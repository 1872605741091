import { Subject } from 'rxjs';

const subject = new Subject();

export enum ToastType {
    SUCCESS = 'success',
    ERROR = 'error',
    WARNING = 'warning'
}

export interface notificationModel {
    message: string;
    type: ToastType;
}

const initialState = {
    data: null,
    hasError: true
};

let state = initialState;

const NotificationStore = {
    init() {
        state = { ...state };
        subject.next(state);
    },
    subscribe: (setState: (value: any) => void) => subject.subscribe(setState),
    showAlert(message: notificationModel) {
        subject.next(message);
    },
    initialState
};

export default NotificationStore;
