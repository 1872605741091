import { Form } from '@formio/react';
import Ratings from 'components/reuseComponents/ratings';
import { Dialog, DialogProps } from 'primereact/dialog';
import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FeedbackForm from './data/feedbackForm.json';
import formStatusStore, { FormStatus } from 'core/store/formIoStore/formIoStore';
import { TransformedMenuItem } from 'layouts/headerMenu/headerMenu';
import { useLocation } from 'react-router-dom';
import { PROTECTED_ROUTES } from 'constants/app.constant';
import AuthStore from 'core/store/authStore/authStore';
import { FeedbackFormUpdate } from './service/feedbackFormService';
import { showToastMessage } from 'core/utils/utils';
import { ToastType } from 'core/store/notificationStore/notificationStore';

type FormData = {
    components: Array<any>;
};

interface FeedbackPopupModalProps {
    visible: boolean;
    pages: TransformedMenuItem[];
    closable?: boolean;
    header?: ReactNode | ((props: DialogProps) => ReactNode);
    onHide?: () => void;
}
export default function FeedbackPopupModal({
    visible,
    header,
    pages = [],
    closable = false,
    onHide = () => {}
}: Readonly<FeedbackPopupModalProps>) {
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const currentPath = pathname.replace('/', '');
    const baseCurrentPath = currentPath.split('/')[0];
    const [ratingValue, setRatingValue] = useState<number>(0);
    const [formState, setFormState] = useState<FormStatus>(formStatusStore.getFormData());
    const userDetails = AuthStore.getUserInfo();
    const {
        signinName: emailId = '',
        userId,
        firstName = '',
        lastName = ''
    } = userDetails?.userInfo || {};

    const isSubmitDisabled = !ratingValue || !formState?.data?.feedback;
    formStatusStore.subscribe(setFormState);

    const onFormChange = (e: any) => {
        if (e.changed && e.changed.component.key !== 'id') {
            const { data, isValid } = e;
            const formattedData: any = data;
            delete formattedData.submit;
            formStatusStore.updateFormData(formattedData, !!isValid, true);
        }
    };

    const transformPages = () => {
        // Add the 'All' menu item and the existing pages
        const currentPages = [...pages];

        // Iterate through protected routes
        PROTECTED_ROUTES.forEach(({ id, route, permission, name }) => {
            // Check if permission is required and if it is granted
            const hasPermission = !permission || AuthStore.hasPermission(permission);

            if (
                hasPermission &&
                !currentPages.some((page) => page.menu === name || page.path === route)
            ) {
                currentPages.push({ id, menu: name, path: `${route}` });
            }
        });

        // Map the currentPages to the desired format
        return currentPages.map(({ id, menu, path, isSubmenu }: TransformedMenuItem) => {
            const isCurrent =
                (menu === t('MENU.DASHBOARD').toLocaleLowerCase() && !path) ||
                path === (isSubmenu ? currentPath : baseCurrentPath);
            const currentPageSuffix = isCurrent ? '(Current Page)' : '';

            return {
                label: `${id}`,
                value: `${menu} ${currentPageSuffix}`
            };
        });
    };

    const updateSelectPageValues = (formData: FormData): FormData => {
        return {
            ...formData,
            components: formData.components.map((component) =>
                component.key === 'feedbackFields'
                    ? {
                          ...component,
                          components: component.components.map((child: any) =>
                              child.key === 'selectedPage'
                                  ? {
                                        ...child,
                                        data: {
                                            ...child.data,
                                            values: transformPages() || []
                                        }
                                    }
                                  : child
                          )
                      }
                    : component
            )
        };
    };

    const updateFeedbackForm = async () => {
        const payload = {
            userId,
            firstName,
            lastName,
            emailId,
            rating: ratingValue,
            pageName: formState?.data?.selectedPage
                ? formState?.data?.selectedPage?.split(' (')?.[0]?.trim()
                : 'All',
            notes: formState?.data?.feedback
        };
        try {
            const response = await FeedbackFormUpdate(payload);
            if (response) {
                showToastMessage({
                    type: ToastType.SUCCESS,
                    message: t('COMMON.FEEDBACK_SUBMISSION.SUCCESS')
                });
                onHide();
                formStatusStore.clearFormData();
            }
        } catch (error) {
            showToastMessage({
                type: ToastType.ERROR,
                message: t('COMMON.FEEDBACK_SUBMISSION.FAILED')
            });
            console.error('Error in updating user feedback:', error);
        }
    };

    const handleSubmit = () => {
        if (!formState?.data?.feedback || !ratingValue) {
            return;
        }
        updateFeedbackForm();
    };

    return (
        <div className="card flex justify-content-center">
            <Dialog
                header={header || t('COMMON.FEEDBACK_APPRECIATE')}
                pt={{
                    root: {
                        className:
                            'w-[90vw] max-w-[25rem] md:w-[80vw] md:max-w-[29rem] h-[80vh] md:h-[74vh] !shadow-none'
                    },
                    header: {
                        className:
                            'justify-center border-b-2 rounded-t-md border-t-4 border-t-secondary'
                    },
                    headerTitle: {
                        className:
                            'text-center font-semibold text-[16px] leading-[20px] md:text-[20px] md:leading-[25px] text-black '
                    }
                }}
                {...{ visible, closable, onHide }}>
                <div
                    data-testid="feedback-container"
                    className="pt-5 flex flex-col justify-center items-center w-full">
                    <div data-testid="rating-container" className="flex flex-col items-center">
                        <p className="text-left font-medium text-[14px] leading-[17px] text-[#484848]">
                            {'How satisfied are you with IPVault?'}
                        </p>
                        <div className="mt-6">
                            <Ratings {...{ ratingValue, setRatingValue }} />
                        </div>
                    </div>

                    <div
                        data-testid="feedback-form-container"
                        className="p-4 px-0 mt-8 bg-neutral-300 w-full bg-none feedback-form">
                        <Form
                            form={updateSelectPageValues(FeedbackForm)}
                            onChange={onFormChange}
                            submission={{ data: formState.data }}
                        />
                    </div>
                    <div className="flex gap-2 w-full justify-end">
                        <button
                            className="btn btn-sm button-neutral font-medium !w-[78px] !h-[36px]"
                            data-testid={'feedback-cancel-button'}
                            onClick={() => {
                                onHide();
                                formStatusStore.clearFormData();
                            }}
                            onKeyDown={() => {}}
                            disabled={false}>
                            {t('COMMON.CANCEL')}
                        </button>
                        <button
                            data-testid={'feedback-submit-button'}
                            className={`btn btn-sm button-primary font-medium !w-[78px] !h-[36px] ${isSubmitDisabled ? 'cursor-not-allowed' : ''}`}
                            onClick={handleSubmit}
                            onKeyDown={() => {}}
                            disabled={isSubmitDisabled}>
                            {t('COMMON.SUBMIT')}
                        </button>
                    </div>
                </div>
            </Dialog>
        </div>
    );
}
