import { Outlet } from 'react-router-dom';
import HeaderMenu from './headerMenu/headerMenu';
import ToastAlert from 'components/toast/toastAlert';
import FileUploadController from 'components/fileUpload/fileUploadController';

function Layout() {
    return (
        <div data-testid="Layout" className="h-screen bg-neutral-100/25 overflow-hidden relative">
            <div className="menuBar fixed top-0 w-full z-[12]">
                <HeaderMenu />
            </div>
            <div className="outlet bg-neutral-100/25 h-[calc(100vh-52px)] 2xl:h-[calc(100vh-61px)] 4xl:h-[calc(100vh-69px)] mt-[4.3rem]">
                <ToastAlert />
                <div className="outlet bg-neutral-100/25 h-full overflow-auto">
                    <Outlet />
                </div>
            </div>
            <FileUploadController />
        </div>
    );
}

export default Layout;
