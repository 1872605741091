// Check if service workers are supported by the browser
export function register() {
    if ('serviceWorker' in navigator) {
        // Register the service worker
        window.addEventListener('load', () => {
            const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;

            navigator.serviceWorker
                .register(swUrl)
                .then(() => {
                    console.error('Service Worker registered.');
                })
                .catch((error) => {
                    console.error('Service Worker registration failed:', error);
                });
        });
    }
}

// Unregister the service worker
export function unregister() {
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.ready
            .then((registration) => {
                registration.unregister();
                console.error('Service Worker un registered');
            })
            .catch((error) => {
                console.error('Service Worker unregistration failed:', error);
            });
    }
}

export {};
