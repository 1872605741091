import { FC, useRef, useState } from 'react';
import { Toast, ToastProps } from 'primereact/toast';
import NotificationStore, {
    ToastType,
    notificationModel
} from 'core/store/notificationStore/notificationStore';

import errorIcon from 'assets/images/toast-alert-error-icon.svg';
import warningIcon from 'assets/images/warning_black.svg';

const ToastAlert: FC = () => {
    const toastRef = useRef<Toast>(null);
    const position = 'top-right';
    const life = 3000;

    const [errorType, setErrorType] = useState('');
    const [typeTextColor, setTypeTextColor] = useState('');

    NotificationStore.subscribe((alert: notificationModel) => {
        if (alert.type === ToastType.SUCCESS) {
            setErrorType('bg-success');
            setTypeTextColor('text-white');
        } else if (alert.type === ToastType.ERROR) {
            setErrorType('bg-error');
            setTypeTextColor('text-white');
        } else {
            setErrorType('bg-toast-warning');
            setTypeTextColor('text-black');
        }
        toastRef.current?.clear();
        toastRef.current?.show({ detail: toastMessageContent(alert?.message, alert.type), life });
    });

    const toastMessageContent = (toastMessage: string, type: ToastType) => (
        <div className={`flex gap-3 items-center`}>
            {type === ToastType.SUCCESS && (
                <i className="pi pi-check-circle text-3xl font-normal"></i>
            )}
            {type === ToastType.ERROR && <img alt="icon" src={errorIcon} />}
            {type === ToastType.WARNING && <img alt="icon" src={warningIcon} />}

            <h5 className="font-medium">{toastMessage}</h5>
        </div>
    );

    return (
        <div data-testid="Toast">
            <Toast
                ref={toastRef}
                position={position as ToastProps['position']}
                pt={{
                    root: {
                        className:
                            'opacity-100 w-10/12 sm:w-7/12 md:w-5/12 lg:w-4/12 xl:w-1/5 ToastPopupResponsive'
                    },
                    content: {
                        className: `p-0 rounded-lg ${errorType} ${typeTextColor}`
                    },
                    closeButton: {
                        className: 'mr-2'
                    },
                    summary: {
                        className: 'hidden'
                    },
                    detail: {
                        className: 'mt-0 h-full flex items-center'
                    },
                    text: {
                        className: 'py-4'
                    }
                }}
            />
        </div>
    );
};

export default ToastAlert;
