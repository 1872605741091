import { Permissions } from './permissions';
import { PATHS } from './routes.constant';

export type DropdownSelection = 'singleSelect' | 'multiSelect';
export type ProtectedRoute = {
    id: number;
    route: keyof typeof PATHS;
    permission?: string;
    name: string;
};

export const PROTECTED_ROUTES: ProtectedRoute[] = [
    {
        id: 19,
        name: 'Account Settings',
        route: PATHS.ACCOUNT_SETTINGS,
        permission: Permissions.UPDATE_USER_PROFILE
    },
    {
        id: 20,
        name: 'PV Settings',
        route: PATHS.PV_SETTINGS,
        permission: Permissions.VIEW_IP_VAULT_SETTINGS
    },
    {
        id: 21,
        name: 'Standard Details',
        route: PATHS.STANDARD,
        permission: Permissions.VIEW_QUOTE
    }
];

export const ROLE = {
    // Staff Users
    ADMIN: { roleId: 1, role: 'Admin' },
    PROJECT_MANAGER: { roleId: 2, role: 'Project Manager' },
    SALES_REP: { roleId: 3, role: 'Sales Rep' },

    // Contact Users
    CONTACT_MANAGER: { roleId: 5, role: 'Contact Manager' },
    CONTACT_USER: { roleId: 6, role: 'Contact User' },

    // Vendor Users
    VENDOR_ADMIN: { roleId: 10, role: 'Vendor Admin' },
    VENDOR_PROJECT_MANAGER: { roleId: 8, role: 'Vendor Project Manager' },
    VENDOR_PROJECT_COORDINATOR: { roleId: 9, role: 'Vendor Project Coordinator' },
    VENDOR_SALES_REP: { roleId: 11, role: 'Vendor Sales' },

    // Other Vendor Users
    HR: { roleId: 4, role: 'HR' },
    VENDOR_HR: { roleId: 12, role: 'Vendor HR' },
    FINANCE: { roleId: 13, role: 'Finance' },
    VENDOR_FINANCE: { roleId: 14, role: 'Vendor Finance' },
    VENDOR_SPECIALIST: { roleId: 7, role: 'Vendor Specialist' } // lowest vendor user role
};

export const MODE = {
    // quote
    CREATE_QUOTE: 1,
    EDIT_QUOTE: 3,

    // order
    CREATE_ORDER: 2,
    EDIT_ORDER: 5
};

export const FLOW = {
    CREATE: 1,
    EDIT: 2
};

export const STATUS_MODE = {
    PREPARING_QUOTE: 1,
    WAITING_FOR_APPROVAL: 2,
    TO_BE_PROCESSED: 3,
    IN_PROGRESS: 4,
    CANCELLED: 5,
    ON_HOLD: 6,
    COMPLETED: 7,
    INVOICED: 8
};

export const NUMBER_TO_BOOLEAN: { [key: number]: boolean } = {
    1: true,
    2: false
};

export const MODULE_TYPE = {
    QUOTE_ORDER_MODULE: { moduleId: 1, azureFolderName: 'order', moduleReferenceName: 'quote' },
    USER_MODULE: { moduleId: 2, azureFolderName: 'user', moduleReferenceName: 'user' },
    VENDOR_USER_MODULE: { moduleId: 8, azureFolderName: 'user', moduleReferenceName: 'user' },
    TASK_MODULE: { moduleId: 3, azureFolderName: 'order', moduleReferenceName: 'task' },
    TASK_MODULE_VENDOR: { moduleId: 5, azureFolderName: 'order', moduleReferenceName: 'task' },
    FILED_DOCUMENT_MODULE: { moduleId: 3, azureFolderName: 'order', moduleReferenceName: 'filed' },
    FORMAL_DOCUMENT_MODULE: {
        moduleId: 4,
        azureFolderName: 'order',
        moduleReferenceName: 'formal'
    },
    FORMAL_DOCUMENT_MODULE_CM_CU: {
        moduleId: 6,
        azureFolderName: 'order',
        moduleReferenceName: 'formal'
    },
    COMPANY_DOCUMENT_MODULE: {
        moduleId: 7,
        azureFolderName: 'company',
        moduleReferenceName: 'company'
    }
};

export const PATENT_SOURCE_LANGUAGE = {
    ENGLISH: 'EN',
    FRENCH: 'FR',
    GERMANY: 'DE'
};

export const STATUS_TYPE_ID = {
    DOCUMENT_IN_REVIEW: 25,
    DOCUMENT_APPROVED: 26,
    DOCUMENT_REJECTED: 27,
    PENDING: 18,
    WAITING_FOR_APPROVAL: 19,
    APPROVED: 20,
    REJECTED: 21,
    NOT_YET_RECEIVED: 22,
    DISPATCHED: 23,
    RECEIVED: 24,
    SENT_FOR_SIGNATURE: 29,
    REMINDER_SENT: 30,
    COMPLETED: 31
};

export const DOCUMENT_TYPE: {
    [key: string]: { id: number; name: string };
} = {
    NOT_CLASSIFIED: { id: 0, name: 'Not Classified' },
    SOURCE: { id: 1, name: 'Source Documents' },
    REFERENCE: { id: 2, name: 'Reference Document' },
    TRANSLATED: { id: 3, name: 'Translated Document' },
    AGREEMENT_DISCLOSURE: { id: 4, name: 'Agreement/Disclosure Document' },
    CV_RESUME_CERTIFICATION: { id: 5, name: 'CV/Resume/Certification Document' },
    TECHNICAL_EVALUATION: { id: 6, name: 'Technical Evaluation Document' },
    TAX_FORM: { id: 7, name: 'Tax Form Document' },
    AUDIT_ESCALATION: { id: 8, name: 'Audit/Escalation Form Document' },
    CHANGE_OF_INFORMATION: { id: 9, name: 'Change of Information Document' },
    OTHERS_DOCUMENT: { id: 10, name: 'Others Document' },
    BILL: { id: 11, name: 'Bill Document' },
    POA: { id: 12, name: 'PoA Document' },
    SIGNED_POA: { id: 13, name: 'Signed PoA Document' },
    ASSIGNMENT: { id: 14, name: 'Assignment Document' },
    SIGNED_ASSIGNMENT: { id: 15, name: 'Signed Assignment Document' },
    TRANSLATOR_COMMENTS: { id: 16, name: 'Translator comments' },
    OTHERS: { id: 17, name: 'Others' },
    FINANCIAL: { id: 18, name: 'Financial' },
    PAYMENT_PRIORITY_DOCUMENT: { id: 20, name: 'Payment Priority Document' },
    DIRECT_DEBIT_WIRE_DOCUMENT: { id: 21, name: 'Direct Debit/Wire Document' },
    OFFICIAL_FILING_RECIPT: { id: 21, name: 'Official Filing Receipt' },
    EXECUTED_POA_DOCUMENT: { id: 21, name: 'Executed PoA Document' }
};

export const SERVICE_TYPE_CATEGORY_ID = {
    Wipo: 1,
    Epo: 2,
    Upo: 3,
    NoDb: 4,
    Other: 5
};

export const SERVICE_TYPE_CATEGORY = {
    STANDARD: 5
};

export const CATEGORY_TYPE = {
    patent_cooperation_treaty: 'patent_cooperation_treaty',
    european_patent: 'european_patent',
    paris_convention_treaty: 'paris_convention_treaty',
    other_translations: 'other_translations'
};

export const SERVICE_TYPES = {
    translation_only: 'translation_only',
    translation_and_filing: 'translation_&_filing',
    recordal: 'recordal',
    annuities: 'annuities',
    claims: '71_3_claims',
    translation_and_validation: 'translation_&_validation',
    renewals: 'Renewals'
};

export const IP_NONIP = {
    nonIp: 'Standard',
    ip: 'IP'
};

export const LANGUAGE_ID = {
    ENGLISH: 38,
    FRENCH: 51,
    SPANISH: 125,
    GERMAN: 59
};

export const REGEX_CONST = {
    NUMERIC_WITH_DECIMALS: /^\d*\.?\d{0,5}$/ // allow only numbers and up to 5 digits after the decimal
};

export const FILE_EXPORT_TYPE: { [key: string]: number } = {
    EXCEL: 1,
    PDF: 2
};

export const YES_NO = {
    YES: 1,
    NO: 2
};
export const YES_NO_STRING = {
    /** use toLowerCase() while compare. 
    eg => showInQuote.toLowerCase() === YES_NO_STRING.YES. */
    YES: 'yes',
    NO: 'no'
};

export const SCREEN_RESOLUTION = {
    TABLET: 768
};

export const COMMON_CONSTANTS = {
    NOT_APPLICABLE: 'N/A'
};

export const Status = {
    WAITING_FOR_APPROVAL: 2,
    TO_BE_PROCESSED: 3
};

export const SAVE_APPROVE_NUM = {
    SAVE: 1,
    APPROVE: 2
};

export const SAVE_APPROVE_BOOL = {
    APPROVE: true,
    SAVE: false
};

export const STD_PREVIEW_TEMPLATE = {
    BIGIP_Quote: {
        id: 14,
        name: 'BIGIP_Quote'
    },
    BIGIP_Quote_SalesOrder_NoRate_Qty: {
        id: 15,
        name: 'BIGIP_Quote_SalesOrder_NoRate_Qty'
    }
};
// confirmation popup - ID is used to identify the request
export const CONFIRMATION_POPUP_REQUEST_TYPE_ID = {
    VENDOR_ASSIGN: 'VENDOR ASSIGN',
    TASK_STATUS_CHANGE: 'TASK STATUS CHANGE'
};
