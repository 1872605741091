import { Link } from 'react-router-dom';
import logo from 'assets/svgs/icon_404.svg';

const ErrorPage = () => {
    return (
        <div
            data-testid="ErrorPage"
            className="flex items-center justify-center min-h-screen bg-white py-48">
            <div className="flex flex-col">
                <div className="flex flex-col items-center">
                    <img src={logo} alt="organization logo" className="max-w-80" srcSet="" />

                    <div className="font-bold text-3xl xl:text-3xl lg:text-3xl md:text-3xl mt-10">
                        This page does not exist
                    </div>

                    <div className="text-gray-400 md:text-xl lg:text-1xl mt-8">
                        Try searching again, or return home to start from the beginning.
                    </div>
                    <Link
                        to="/"
                        className="mt-6 inline-block rounded bg-primary px-5 py-3 text-sm font-medium text-white hover:bg-primary focus:outline-none focus:ring">
                        Go Back Home
                    </Link>
                </div>
            </div>
        </div>
    );
};
export default ErrorPage;
