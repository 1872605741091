import { Rating, RatingChangeEvent } from 'primereact/rating';

interface RatingsProps {
    ratingValue: number;
    setRatingValue: (value: number) => void;
}
export default function Ratings({ ratingValue, setRatingValue }: Readonly<RatingsProps>) {
    return (
        <div className="card flex justify-content-center">
            <Rating
                value={ratingValue}
                cancel={false}
                onChange={(e: RatingChangeEvent) => setRatingValue(e.value || 0)}
                pt={{
                    item: {
                        className: 'shadow-none',
                        ['data-testid']: 'rating-star'
                    }
                }}
                onIcon={
                    <img
                        src="/images/svgs/filled-star.svg"
                        alt="custom-image-active"
                        width="38px"
                        height="38px"
                        className="mx-3"
                    />
                }
                offIcon={
                    <img
                        src="/images/svgs/star.svg"
                        alt="custom-image"
                        width="38px"
                        height="38px"
                        className="mx-3"
                    />
                }
            />
        </div>
    );
}
