import HttpService from 'core/interceptor/api.handler';
import { FeedbackFormPayload } from '../types/feedbackForm.types';
import { END_POINT } from 'constants/endPoint';

const _httpService = new HttpService();
const baseURL = process.env.REACT_APP_API_BASE_URL ?? '';

/**
 * Used to get user's feedback
 * @param payload
 * @returns
 */
export const FeedbackFormUpdate = async <T>(payload: FeedbackFormPayload): Promise<T> => {
    const apiUrl = `${baseURL}${END_POINT.USER_FEEDBACK}`;
    try {
        const response = await _httpService.post<T, FeedbackFormPayload>(apiUrl, payload);
        return response;
    } catch (error) {
        console.error('Error updating user feedback:', error);
        throw error;
    }
};
