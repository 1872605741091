import './App.css';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { router } from 'core/routes';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from 'core/configs/msalConfig';
import React from 'react';
import { getToken } from 'core/interceptor/api.handler';

const PageHasBeenForceRefreshed = 'page-has-been-force-refreshed';

const retryPageLoading = () => {
    const pageHasAlreadyBeenForceRefreshed = JSON.parse(
        window.localStorage.getItem(PageHasBeenForceRefreshed) ?? 'false'
    ) as boolean;

    if (!pageHasAlreadyBeenForceRefreshed) {
        window.localStorage.setItem(PageHasBeenForceRefreshed, 'true');
        return window.location.reload();
    } else {
        window.localStorage.setItem(PageHasBeenForceRefreshed, 'false');
    }
};

interface ErrorBoundaryProps {
    children: React.ReactNode;
}
export class ErrorBoundary extends React.Component<ErrorBoundaryProps, { hasError: boolean }> {
    constructor(props: Readonly<ErrorBoundaryProps>) {
        super(props);
        this.state = { hasError: false };
    }
    componentDidCatch(error: unknown, info: unknown) {
        retryPageLoading();
        this.setState({ hasError: true });
        console.error(error);
        console.error(info);
    }
    render() {
        if (this.state.hasError) {
            return <>Your error component</>;
        }
        return this.props.children;
    }
}

function App() {
    const msalInstance = new PublicClientApplication(msalConfig);
    const routerConfig = createBrowserRouter(router);

    // Checking token every 15 seconds
    setInterval(
        () => {
            getToken().then((_response) => {
                // Token refreshed successfully
            });
        },
        1000 * 60 * 1
    );

    return (
        <MsalProvider instance={msalInstance}>
            <div>
                <ErrorBoundary>
                    <RouterProvider
                        router={routerConfig}
                        fallbackElement={<p>Initial Load...</p>}
                    />
                </ErrorBoundary>
            </div>
        </MsalProvider>
    );
}

export default App;
