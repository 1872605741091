import { BlobServiceClient } from '@azure/storage-blob';
import UploadedFileStore from '../../../core/store/uploadedFile.store/uploadedFile.store';
import HttpService from '../../../core/interceptor/api.handler';
import { deleteTheFileInStorageArray } from '../uploadingFileStatus/uploadingFileStatus';
import NotificationStore, {
    notificationModel,
    ToastType
} from 'core/store/notificationStore/notificationStore';

// Map to store AbortControllers for each file upload
const abortControllers = new Map();
const _httpService = new HttpService();
let sasUrl: string = '';
const baseURL: string | undefined = process.env.REACT_APP_QUOTE_BASE_URL;
const skipFileUpload =
    (process.env.REACT_APP_DISABLE_AZURE_FILE_UPLOAD ?? 'false').toLowerCase() === 'true';
// Check if baseURL is defined before proceeding
if (!baseURL) {
    throw new Error('REACT_APP_API_BASE_URL is not defined in the environment variables.');
}

export async function getSasUrl() {
    const response: string = await _httpService.get(`${baseURL}/patentservice/getsasurl`, {
        headers: {
            Accept: '*/*'
        }
    });
    sasUrl = response;
    return sasUrl;
}

export async function isSasUrlExpired(url: string) {
    // Use URLSearchParams to parse query parameters from the URL
    const urlParams = new URLSearchParams(url);

    // Extract the 'se' (expiry) parameter, which is in ISO 8601 format
    const expiryTime = urlParams.get('se');

    if (!expiryTime) {
        return;
    }

    // Parse the expiry time into a Date object
    const expiryDate = new Date(expiryTime);

    // Get the current time in UTC
    const currentDate = new Date();

    // Compare current time with expiry time
    if (currentDate > expiryDate) {
        return await getSasUrl();
    } else {
        return url;
    }
}

export async function handleAzureStorageUploadTheFile(
    file: File,
    azureBlobFolderPath: string,
    azureBlobFileName: string
) {
    const newSasUrl: string = (await isSasUrlExpired(sasUrl)) || '';
    const blobServiceClient = new BlobServiceClient(newSasUrl);
    const containerClient = blobServiceClient.getContainerClient(azureBlobFolderPath);

    try {
        const blobClient = containerClient.getBlockBlobClient(azureBlobFileName);
        const fileSize = file.size;

        const onprogress = (ev: { loadedBytes: number }) => {
            const percentage = (ev.loadedBytes / fileSize) * 100;
            UploadedFileStore.UploadingFileStatus(file.name, percentage.toFixed(2));
        };

        const controller = new AbortController();
        abortControllers.set(file.name, controller);

        if (!skipFileUpload) {
            try {
                await blobClient.uploadData(file, {
                    blockSize: 2 * 1024 * 1024,
                    concurrency: 20,
                    maxSingleShotSize: 2 * 1024 * 1024, // 2 MB
                    onProgress: onprogress,
                    blobHTTPHeaders: { blobContentType: file.type },
                    abortSignal: controller.signal
                });
            } catch (error: any) {
                const message: notificationModel = {
                    message: `Error occurred during upload of ${file.name}:`,
                    type: ToastType.ERROR
                };
                NotificationStore.showAlert(message);
                deleteTheFileInStorageArray(file.name);
                if (error.name !== 'AbortError') {
                    console.error(`Error occurred during upload of ${file.name}:`, error);
                }
            }
        } else {
            await new Promise((r) => setTimeout(r, 500));
            onprogress({ loadedBytes: fileSize });
        }
    } catch (error) {
        console.error('Error occurred during upload:', error);
    }
}
export function cancelFileUpload(fileName: string) {
    const controller = abortControllers.get(fileName);
    if (controller) {
        controller.abort();
        deleteTheFileInStorageArray(fileName);
    }
}
