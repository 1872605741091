import AuthStore from 'core/store/authStore/authStore';
import { LoaderFunctionArgs, redirect } from 'react-router-dom';
import { PROTECTED_ROUTES } from 'constants/app.constant';
import { generateHeaderMenu } from 'layouts/headerMenu/headerMenu.constant';

export function protectedLoader(
    { request }: LoaderFunctionArgs,
    requiredPermissionOrPermissions?: string | string[]
) {
    const from = new URL(request?.url ?? '', window.location.origin).pathname;
    if (!AuthStore.isAuthenticated()) {
        localStorage.setItem('redirectUrl', from);
        return redirect(`/login?from=${encodeURIComponent(from)}`);
    }

    // Handle both single and multiple permissions
    let hasRequiredPermission = true;
    if (requiredPermissionOrPermissions) {
        if (Array.isArray(requiredPermissionOrPermissions)) {
            // Check if the user has any of the required permissions
            hasRequiredPermission = requiredPermissionOrPermissions.some((permission) =>
                AuthStore.hasPermission(permission)
            );
        } else {
            // Single permission check
            hasRequiredPermission = AuthStore.hasPermission(requiredPermissionOrPermissions);
        }

        if (!hasRequiredPermission) {
            return redirect('/access-denied');
        }
    }

    const fromURL = from.replace('/', '').split('/')[0];
    const currentMenu = generateHeaderMenu().find((menu) => menu.path === fromURL);
    // Check user permissions if requiredPermission is provided
    let hasPermission = false;
    currentMenu?.permission?.forEach((menu) => {
        if (AuthStore.hasPermission(menu)) {
            hasPermission = true;
        }
    });

    // For routes that don't require a header menu and the user is authenticated:
    if (!currentMenu && AuthStore.isAuthenticated()) {
        // Iterate over each protected route to check permissions:
        PROTECTED_ROUTES.forEach(({ permission, route }) => {
            // Check if the route matches the fromURL
            if (`${route}` === fromURL) {
                // If a permission is specified, check if the user has it
                // If no permission is specified, the route is considered accessible
                hasPermission = permission ? AuthStore.hasPermission(permission) : true;
            }
        });
    }

    if (!hasPermission) {
        // If the user does not have the required permission, redirect them to a page indicating access denied
        return redirect('/access-denied');
    }
    // If the user is authenticated and has the required permission, return null to allow access to the route
    return null;
}
