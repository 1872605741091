import { FC, useEffect, useLayoutEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import UploadedFileStore from '../../../core/store/uploadedFile.store/uploadedFile.store';
import { cancelFileUpload } from '../services/azureStorage.service';
import { fileTypeIcon } from '../constant/fileTypeIcon';

export const deleteTheFileInStorageArray = (filename: string) => {
    UploadedFileStore.deleteTheFile(filename);
};

export const formatFileSize = (size: number): string => {
    if (size < 1024) {
        return `${size} bytes`;
    } else if (size < 1024 * 1024) {
        return `${(size / 1024).toFixed(2)} KB`;
    } else if (size < 1024 * 1024 * 1024) {
        return `${(size / (1024 * 1024)).toFixed(2)} MB`;
    } else {
        return `${(size / (1024 * 1024 * 1024)).toFixed(2)} GB`;
    }
};

interface UploadingFileStatusProps {
    moduleName: string;
}

const UploadingFileStatus: FC<UploadingFileStatusProps> = (props: UploadingFileStatusProps) => {
    const { t } = useTranslation();
    const [uploadedFiles, setUploadedFiles] = useState<any>(UploadedFileStore.initialState.files);

    useLayoutEffect(() => {
        const subscription = UploadedFileStore.subscribe(setUploadedFiles);
        return () => {
            subscription?.unsubscribe();
        };
    }, []);

    const [moduleInProgressFileCount, setModuleInProgressFileCount] = useState<number>(0);

    useEffect(() => {
        const count: number = uploadedFiles.filter(
            (file: { progress: number; moduleName: string }) =>
                file.moduleName === props.moduleName &&
                file.progress >= 0.1 &&
                file.progress <= 99.99
        ).length;
        setModuleInProgressFileCount(count);
    }, [uploadedFiles]);

    const [moduleCompletedFileCount, setModuleCompletedFileCount] = useState<number>(0);

    useEffect(() => {
        const count: number = uploadedFiles.filter(
            (file: { progress: number; moduleName: string }) =>
                file.moduleName === props.moduleName && file.progress >= 100.0
        ).length;
        setModuleCompletedFileCount(count);
    }, [uploadedFiles]);

    const [moduleTotalFileCount, setModuleTotalFileCount] = useState<number>(0);

    useEffect(() => {
        const count: number = uploadedFiles.filter(
            (file: { moduleName: string }) => file.moduleName === props.moduleName
        ).length;
        setModuleTotalFileCount(count);
    }, [uploadedFiles]);

    const filesInprogressCount =
        uploadedFiles.length > 0 &&
        uploadedFiles.filter(
            (file: { progress: number }) => file.progress >= 0.1 && file.progress <= 99.99
        );
    const count = filesInprogressCount.length;
    const fileCompletedLength =
        uploadedFiles.length > 0 &&
        uploadedFiles.filter((file: { progress: number }) => file.progress >= 100.0);

    return (
        <div data-testid="UploadingFileStatus" className="w-full">
            {(moduleTotalFileCount > 0 || props.moduleName === '') && (
                <div
                    className={`p-3 mt-3  bg-white text-black w-full  rounded-lg border-2 border-neutral/50'}`}>
                    <h5 className="flex items-center gap-2 font-medium w-full">
                        {moduleInProgressFileCount !== 0 &&
                        moduleTotalFileCount !== moduleCompletedFileCount
                            ? `${t('File_UPLOAD.UPLOADING')} ${moduleInProgressFileCount} ${t('File_UPLOAD.FILES')}`
                            : ''}
                        {moduleCompletedFileCount === moduleTotalFileCount &&
                        moduleCompletedFileCount !== 0
                            ? `Uploaded ${moduleCompletedFileCount} ${t('File_UPLOAD.FILES')}`
                            : ''}

                        {props.moduleName === '' && count !== 0
                            ? `${t('File_UPLOAD.UPLOADING')} ${count} ${t('File_UPLOAD.FILES')}`
                            : ''}
                        {props.moduleName === '' &&
                        uploadedFiles.length &&
                        uploadedFiles.length === fileCompletedLength.length
                            ? `Uploaded ${fileCompletedLength.length} ${t('File_UPLOAD.FILES')}`
                            : ''}
                    </h5>
                    <ul className="max-h-48 overflow-auto">
                        {uploadedFiles.map(
                            (file: {
                                name: string;
                                size: number;
                                type: string;
                                progress: number;
                                moduleName: string;
                            }) =>
                                (props.moduleName === file.moduleName ||
                                    props.moduleName === '') && (
                                    <li key={uuidv4()} className="py-2 border-b border-neutral-100">
                                        <div className="flex justify-between items-center gap-6">
                                            <div className="flex flex-col text-base w-full font-medium">
                                                <div>
                                                    <div className="grid gap-1">
                                                        <div className="flex items-center gap-1">
                                                            {fileTypeIcon(file.type)}
                                                            <p className="truncate w-96">
                                                                {file.name}
                                                            </p>
                                                        </div>
                                                        <p className="text-neutral-700">
                                                            {t(`File_UPLOAD.FILE_SIZE`)} :{' '}
                                                            {formatFileSize(file.size)}
                                                        </p>
                                                    </div>
                                                    {file.progress > 0 && (
                                                        <progress
                                                            className="progress progress-primary w-full bg-neutral-100 h-[4px] my-1"
                                                            value={file.progress}
                                                            max="100"></progress>
                                                    )}
                                                </div>
                                            </div>
                                            <div>
                                                {file.progress >= 100.0 ? (
                                                    <i className="pi pi-check-circle text-success pr-3 text-xl" />
                                                ) : (
                                                    file.progress > 0 &&
                                                    file.progress < 100.0 && (
                                                        <i
                                                            className="pi pi-times pr-3 cursor-pointer"
                                                            onClick={() => {
                                                                cancelFileUpload(file.name);
                                                            }}
                                                            onKeyDown={(event) => {
                                                                if (event.key === 'Enter') {
                                                                    cancelFileUpload(file.name);
                                                                }
                                                            }}
                                                        />
                                                    )
                                                )}
                                            </div>
                                        </div>
                                    </li>
                                )
                        )}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default UploadingFileStatus;
