export const ALLOWED_EXTENSION = [
    'pdf', // pdf
    'msword', // doc
    'wordprocessingml.document', // docx
    'ms-excel', // xls
    'spreadsheetml.sheet', // xlsx
    'png', // png
    'svg', // svg
    'zip', // zip
    'csv' // csv
];
