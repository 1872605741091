import AuthStore, { UserInfoModal } from 'core/store/authStore/authStore';
import NotificationStore, {
    ToastType,
    notificationModel
} from 'core/store/notificationStore/notificationStore';
import axios, {
    AxiosInstance,
    AxiosResponse,
    AxiosRequestConfig,
    AxiosError,
    ResponseType
} from 'axios';
import { getDomainName, toggleLoadingIndicator } from 'core/utils/utils';
import { PublicClientApplication, SilentRequest } from '@azure/msal-browser';
import { b2cPolicies, msalConfig } from 'core/configs/msalConfig';
import { jwtDecode } from 'jwt-decode';
import { AuthResponse } from 'pages/login/login';

// COOKIES
export enum EHttpMethod {
    GET = 'GET',
    POST = 'POST',
    PUT = 'PUT',
    DELETE = 'DELETE',
    PATCH = 'PATCH'
}
interface ErrorResponse {
    response?: {
        data?: {
            Message?: string;
            errors?: {
                [key: string]: string[]; // Key-value pair for field-specific validation errors
            };
        };
    };
    message?: string;
}

const isErrorResponse = (error: any): error is ErrorResponse => {
    return (
        error.response &&
        typeof error.response === 'object' &&
        'data' in error.response &&
        typeof error.response.data === 'object'
    );
};

const handleError = (error: ErrorResponse): notificationModel => {
    return {
        type: ToastType.ERROR,
        message: error?.response?.data?.Message ?? error?.message ?? 'An unknown error occurred'
    };
};

const msalInstance = new PublicClientApplication(msalConfig);

export const getToken = async () => {
    const userInfo: UserInfoModal = AuthStore.getUserInfo();

    if (userInfo?.expiresOn > 0 && userInfo?.expiresOn - Date.now() / 1000 < 15 * 60) {
        const accounts = msalInstance.getAllAccounts();
        if (accounts.length === 0) {
            return null;
        }

        const codeRequest: SilentRequest = {
            // Assumes only one account, adjust if needed
            account: msalInstance.getAllAccounts()[0],
            // Scopes for token request
            scopes: [],
            // Authority for token request
            authority: b2cPolicies.authorities.signUpSignIn.authority
        };

        await msalInstance.initialize();

        try {
            const response = await msalInstance.acquireTokenSilent(codeRequest);
            const decoded: AuthResponse = jwtDecode(response?.idToken ? response.idToken : '');

            if (decoded?.isForgotPassword || decoded?.isPasswordReset) {
                msalInstance.logoutRedirect();
                return;
            }

            AuthStore.setLogin(response.idToken, decoded);

            return response.idToken;
        } catch (error: any) {
            console.error(error);
            if (JSON.stringify(error).includes('AADB2C90077')) {
                localStorage.clear();
                msalInstance.loginRedirect();
            } else {
                localStorage.clear();
                window.location.reload();
            }

            return null;
        }
    }
};

class HttpService {
    private readonly http: AxiosInstance;
    public baseURL = process.env.REACT_APP_API_BASE_URL;
    // public baseURL = process.env.REACT_APP_QUOTE_BASE_URL;

    constructor() {
        this.http = axios.create({
            baseURL: this.baseURL,
            withCredentials: false
        });
    }

    // Get authorization token for requests
    private get getAuthorization() {
        const userInfo: UserInfoModal = AuthStore.getUserInfo();
        const accessToken = userInfo?.authToken || '';
        getToken().then((_response) => {
            // Token refreshed
        });
        return accessToken ? { Authorization: `Bearer ${accessToken}` } : {};
    }

    // Set up request headers
    private setupHeaders(hasAttachment = false) {
        return hasAttachment
            ? { 'Content-Type': 'multipart/form-data', ...this.getAuthorization }
            : { 'Content-Type': 'application/json', ...this.getAuthorization };
    }
    public updateJsonDataWithDynamicValues(jsonData: any): any {
        // Replace BASE_URL placeholder with actual base URL
        const stringifiedJSon = JSON.stringify(jsonData);
        let updatedJson = stringifiedJSon.replace(
            /REACT_APP_API_BASE_URL/g,
            process.env.REACT_APP_API_BASE_URL ?? ''
        );
        updatedJson = updatedJson.replace(
            /REACT_APP_QUOTE_BASE_URL/g,
            process.env.REACT_APP_QUOTE_BASE_URL ?? ''
        );
        // Prepend the current domain URL to relative paths
        updatedJson = updatedJson.replace(/REACT_APP_DOMAIN_URL/g, getDomainName() ?? '');

        // Get the authorization token
        const authorization = this.getAuthorization;

        if (authorization?.Authorization) {
            // Update the Authorization header with the actual access token
            updatedJson = updatedJson.replace(
                /"ACCESS_TOKEN_HERE"/g,
                JSON.stringify(authorization.Authorization)
            );
        }

        return JSON.parse(updatedJson);
    }

    // Handle HTTP requests
    private async request<T>(
        method: EHttpMethod,
        url: string,
        options?: AxiosRequestConfig,
        baseUrl?: string,
        isExport = 'json'
    ): Promise<T> {
        // Call the function to show the loader
        if (!options?.params?.data?.hideLoader) {
            toggleLoadingIndicator(true);
        }

        // Checking Token before every API call
        await getToken();

        try {
            const response: AxiosResponse<T> = await this.http.request<T>({
                method,
                url,
                baseURL: baseUrl,
                responseType: isExport as ResponseType,
                ...options
            });
            if (!options?.params?.data?.hideLoader) {
                setTimeout(() => {
                    // Call the function to hide the loader
                    toggleLoadingIndicator(false);
                }, 500);
            }
            return response.data;
        } catch (error) {
            return this.normalizeError(error as AxiosError);
        }
    }

    // Perform GET request
    public async get<T>(
        url: string,
        params?: AxiosRequestConfig,
        hasAttachment = false,
        baseUrl?: string
    ): Promise<T> {
        return this.request<T>(EHttpMethod.GET, url, {
            params,
            baseURL: baseUrl,
            headers: this.setupHeaders(hasAttachment)
        });
    }

    // Perform POST request
    public async post<T, P>(
        url: string,
        payload: P,
        params?: AxiosRequestConfig,
        hasAttachment = false,
        baseUrl?: string
    ): Promise<T> {
        return this.request<T>(EHttpMethod.POST, url, {
            params,
            data: payload,
            baseURL: baseUrl,
            headers: this.setupHeaders(hasAttachment)
        });
    }

    // Perform PUT request
    public async put<T, P>(
        url: string,
        payload?: P,
        params?: AxiosRequestConfig,
        hasAttachment = false,
        baseUrl?: string
    ): Promise<T> {
        return this.request<T>(EHttpMethod.PUT, url, {
            params,
            baseURL: baseUrl,
            data: payload,
            headers: this.setupHeaders(hasAttachment)
        });
    }

    // Perform UPDATE request
    public async update<T, P>(
        url: string,
        payload: P,
        params?: AxiosRequestConfig,
        hasAttachment = false
    ): Promise<T> {
        return this.request<T>(EHttpMethod.PUT, url, {
            params,
            data: payload,
            headers: this.setupHeaders(hasAttachment)
        });
    }

    // Perform DELETE request
    public async delete<T>(
        url: string,
        params?: AxiosRequestConfig,
        hasAttachment = false
    ): Promise<T> {
        return this.request<T>(EHttpMethod.DELETE, url, {
            params,
            headers: this.setupHeaders(hasAttachment)
        });
    }

    // Perform POST request for export
    public async export<T, P>(
        url: string,
        payload: P,
        params?: AxiosRequestConfig,
        hasAttachment = false,
        baseUrl?: string
    ): Promise<T> {
        return this.request<T>(
            EHttpMethod.POST,
            url,
            {
                params,
                data: payload,
                baseURL: baseUrl,
                headers: this.setupHeaders(hasAttachment)
            },
            '',
            'blob'
        );
    }

    /**
     * Performs a PATCH request to the specified URL with the given payload.
     * @param url - The URL to send the PATCH request to.
     * @param payload - The data payload to include in the request body.
     * @param params - Optional additional configuration for the request.
     * @param hasAttachment - Optional flag indicating whether the request includes file attachments.
     * @param baseUrl - Optional base URL to override the default base URL.
     * @returns A Promise resolving to the response data of type T.
     * @throws If the request fails or encounters an error.
     */
    public async patch<T, P>(
        url: string,
        payload: P,
        params?: AxiosRequestConfig,
        hasAttachment = false,
        baseUrl?: string
    ): Promise<T> {
        return this.request<T>(EHttpMethod.PATCH, url, {
            params,
            data: payload,
            baseURL: baseUrl,
            headers: this.setupHeaders(hasAttachment)
        });
    }

    // Normalize errors
    private normalizeError(error: AxiosError) {
        if (error.response?.status === 401) {
            localStorage.clear();
            window.location.href = '/login?from=' + window.location.pathname;
        }
        if (error.response?.status === 403) {
            localStorage.clear();
            window.location.href = '/access-denied';
        }
        setTimeout(() => {
            toggleLoadingIndicator(false);
        }, 500);

        if (isErrorResponse(error)) {
            // Check for specific validation error message
            const errorMessages = error.response?.data?.errors || {};
            const hasHtmlError = Object.values(errorMessages)
                .flat()
                .some((msg: string) => msg.includes('contain HTML'));

            const toast = handleError({
                ...error,
                response: {
                    ...error.response,
                    data: {
                        Message: hasHtmlError
                            ? 'We are unable to process your request because disallowed HTML text was detected.'
                            : error.response?.data?.Message
                    }
                }
            });

            NotificationStore.showAlert(toast);
        } else {
            // Handle the case where error does not conform to ErrorResponse
            NotificationStore.showAlert({
                type: ToastType.ERROR,
                message: 'An unknown error occurred'
            });
        }

        return Promise.reject(error);
    }

    // New method to download files
    public async downloadFile(url: string, hasAttachment = false, baseUrl?: string): Promise<Blob> {
        try {
            const response: AxiosResponse<ArrayBuffer> = await this.http.get(url, {
                responseType: 'arraybuffer',
                baseURL: baseUrl,
                headers: this.setupHeaders(hasAttachment)
            });

            // Create a Blob from the binary data
            const blob = new Blob([response.data], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            });

            return blob;
        } catch (error) {
            this.normalizeError(error as AxiosError);
            throw error;
        }
    }
    public async uploadVendorBreakdownRequest(
        url: string,
        file: File,
        baseUrl?: string
    ): Promise<AxiosResponse<ArrayBuffer>> {
        const formData = new FormData();
        formData.append('file', file);

        try {
            const response = await this.http.post(url, formData, {
                responseType: 'arraybuffer',
                baseURL: baseUrl,
                headers: this.setupHeaders(true) // Ensures the authorization header is included
            });

            return response;
        } catch (error) {
            this.normalizeError(error as AxiosError);
            console.error(`Error uploading vendor breakdown file:`, error);
            throw error;
        }
    }
}

export { HttpService as default };
