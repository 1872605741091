import { jwtDecode } from 'jwt-decode';
import { AuthResponse } from 'pages/login/login';
import { Subject } from 'rxjs';

const subject = new Subject<any>();

export interface UserInfoModal {
    userInfo: UserDetailsModal;
    authToken: string;
    expiresOn: number;
    isAuthenticated: boolean;
}

export interface UserDetailsModal {
    b2cuniqueId?: string;
    signinName?: string;
    permissions: string[];
    userId: number;
    userFullName: string;
    firstName?: string;
    lastName?: string;
    role: string;
    roleId: number;
    company?: string;
    companyId?: number;
}

const initialState: UserInfoModal = {
    userInfo: {
        permissions: [],
        userId: 0,
        userFullName: '',
        role: '',
        roleId: 0
    },
    expiresOn: 0,
    authToken: '',
    isAuthenticated: false
};

let state: UserInfoModal = initialState;

const AuthStore = {
    init() {
        state = { ...initialState };
        subject.next(state);
    },
    subscribe: (setState: (value: UserInfoModal) => void) => subject.subscribe(setState),
    setLogin(idToken: string, decoded: AuthResponse) {
        const userInfo: UserInfoModal = {
            isAuthenticated: true,
            expiresOn: 0,
            userInfo: {
                permissions: [],
                userId: 0,
                userFullName: '',
                role: '',
                roleId: 0
            },
            authToken: idToken ?? ''
        };

        if (decoded?.authresp) {
            // Store token in local storage
            localStorage.setItem('JwtToken', idToken ?? '');
            // Parse and set user information
            userInfo.expiresOn = decoded.exp ?? 0;
            userInfo.userInfo = JSON.parse(decoded.authresp);
        } else {
            // If decoding fails, use cached token for user information
            const cacheToken = localStorage.getItem('JwtToken');
            if (cacheToken) {
                const cacheDecoded: AuthResponse = jwtDecode(cacheToken);
                userInfo.userInfo = JSON.parse(cacheDecoded.authresp);
            }
        }
        state = {
            ...userInfo
        };
        subject.next(state);
    },
    isAuthenticated() {
        return state.isAuthenticated;
    },
    hasPermission(permission: string): boolean {
        return state?.userInfo?.permissions?.includes(permission);
    },
    logout() {
        localStorage.clear();
        state = initialState;
        subject.next(state);
    },
    getUserInfo() {
        return state;
    },
    getInitialState() {
        return initialState;
    }
};

export default AuthStore;
