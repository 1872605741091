import React from 'react';
import ReactDOM from 'react-dom/client';
import './style.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import i18n from 'core/i18next/i18next';
import { PrimeReactProvider } from 'primereact/api';
import Tailwind from 'primereact/passthrough/tailwind';
import { twMerge } from 'tailwind-merge';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

export const rootElement = document.getElementById('root') as HTMLElement;
i18n.changeLanguage('en');
const root = ReactDOM.createRoot(rootElement);
root.render(
    <React.StrictMode>
        <PrimeReactProvider
            value={{
                unstyled: true,
                pt: Tailwind,
                ptOptions: {
                    mergeSections: true,
                    mergeProps: true,
                    classNameMergeFunction: twMerge
                }
            }}>
            <App />
        </PrimeReactProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

if (navigator.serviceWorker) {
    const swUrl = './service-worker.js';

    navigator.serviceWorker
        .register(swUrl, { scope: '/' })
        .then(function (registration) {
            console.error('Service worker registered with scope:', registration.scope);
        })
        .catch(function (error) {
            console.error('Service worker registration failed:', error);
        });

    navigator.serviceWorker.addEventListener('message', (event) => {
        // event is a MessageEvent object
        console.error(`The service worker sent me a message: ${event.data}`);
    });

    navigator.serviceWorker.ready.then((registration) => {
        if (registration && registration.active) {
            registration.active.postMessage('Hi service worker');
        }
    });
}

addEventListener('beforeunload', () => {});
onbeforeunload = () => {
    serviceWorkerRegistration.unregister();
};
