export const Permissions = {
    VIEW_DASHBOARD: 'viewdashboard',
    CREATE_QUOTE: 'createquote',
    VIEW_QUOTE: 'viewquote',
    EDIT_QUOTE: 'editquote',
    CREATE_ORDER: 'createorder',
    VIEW_ORDER: 'vieworder',
    EDIT_ORDER: 'editorder',
    EXPORT_QUOTE: 'exportquote',
    APPROVE_QUOTE: 'approvequote',
    DELETE_QUOTE: 'deletequote',
    DELETE_ORDER: 'deleteorder',

    CREATE_TASK: 'createtask',
    EDIT_TASK: 'edittask',
    VIEW_TASK: 'viewtask',
    ASSIGN_TASK: 'assigntask',
    ACCEPT_REJECT_TASK: 'accept/reject task',

    CREATE_COMPANIES: 'createcompanies',
    EDIT_COMPANIES: 'editcompanies',
    VIEW_COMPANIES: 'viewcompanies',
    CREATE_SUB_COMPANIES: 'createsubcompanies',
    EDIT_SUB_COMPANIES: 'editsubcompanies',
    VIEW_SUB_COMPANIES: 'viewsubcompanies',

    CREATE_STAFF_USER: 'createstaffuser',
    EDIT_STAFF_USER: 'editstaffuser',
    VIEW_STAFF_USER: 'viewstaffuser',

    VIEW_CONTACT_PROFILE: 'viewcontactprofile',
    ADD_CONTACT_USER: 'addcontactuser',
    EDIT_CONTACT_USER: 'editcontactuser',

    CREATE_VENDOR_USERS: 'createvendorusers',
    VIEW_VENDOR_USERS: 'viewvendorusers',
    EDIT_VENDOR_USERS: 'editvendorusers',

    CREATE_V3_VENDOR_USERS_ONLY: 'createV3vendorusersonly',
    VIEW_V3_VENDOR_USERS_ONLY: 'viewV3vendorusersonly',
    EDIT_V3_VENDOR_USERS_ONLY: 'editV3vendorusersonly',

    VIEW_VENDOR_RATES_V1: 'viewvendorratesv1',
    VIEW_VENDOR_RATES_V2: 'viewvendorratesv2',
    VIEW_VENDOR_RATES_V3: 'viewvendorratesv3',

    CREATE_BILLING_TERMS: 'createbillingterms',
    EDIT_BILLING_TERMS: 'editbillingterms',
    VIEW_BILLING_TERMS: 'viewbillingterms',

    CREATE_REQUEST_CURRENCIES: 'createrequestcurrencies',
    EDIT_REQUEST_CURRENCIES: 'editrequestcurrencies',
    VIEW_REQUEST_CURRENCIES: 'viewrequestcurrencies',

    CREATE_VENDOR_CURRENCIES: 'createvendorcurrencies',
    EDIT_VENDOR_CURRENCIES: 'editvendorcurrencies',
    VIEW_VENDOR_CURRENCIES: 'viewvendorcurrencies',

    CREATE_LANGUAGES: 'createlanguages',
    EDIT_LANGUAGES: 'editlanguages',
    VIEW_LANGUAGES: 'viewlanguages',

    CREATE_UNIT: 'createunit',
    EDIT_UNIT: 'editunit',
    VIEW_UNIT: 'viewunit',

    CREATE_IP_INSTRUCTION_DEADLINES: 'createipinstructiondeadlines',
    EDIT_IP_INSTRUCTION_DEADLINES: 'editipinstructiondeadlines',
    VIEW_IP_INSTRUCTION_DEADLINES: 'viewipinstructiondeadlines',

    CREATE_PAYMENT: 'createpayment',
    EDIT_PAYMENT: 'editpayment',
    VIEW_PAYMENT: 'viewpayment',

    CREATE_TAX_FORMS: 'createtaxforms',
    EDIT_TAX_FORMS: 'edittaxforms',
    VIEW_TAX_FORMS: 'viewtaxforms',

    CREATE_IP_VAULT_SETTINGS: 'createipvalutsettings',
    EDIT_IP_VAULT_SETTINGS: 'editipvalutsettings',
    VIEW_IP_VAULT_SETTINGS: 'viewipvalutsettings',

    VIEW_INVOICE: 'viewinvoice',
    RESYNC_INVOICE: 'resyncinvoice',
    DOWNLOAD_INVOICE: 'downloadinvoice',

    VIEW_BILLS: 'viewbills',
    RESYNC_BILLS: 'resyncbills',
    DOWNLOAD_BILLS: 'downloadbills',

    UPDATE_USER_PROFILE: 'updateuserprofile',

    CREATE_INVOICE_TASK: 'createinvoicetask',
    EDIT_INVOICE_TASK: 'editinvoicetask',
    VIEW_INVOICE_TASK: 'viewinvoicetask',

    ACCOUNTING_INVOICES: 'accountinginvoices',

    CREATE_BILL_TASK: 'createbilltask',
    EDIT_BILL_TASK: 'editbilltask',
    VIEW_BILL_TASK: 'viewbilltask',
    ACCEPT_BILL_TASK: 'acceptbilltask',

    ACCOUNTING_BILLS: 'accountingbills',
    EDIT_BREAKDOWN: 'editbreakdown',
    VIEW_BREAKDOWN: 'viewbreakdown',

    SETTING_CREATE_TASK: 'settingcreatetask',
    SETTING_EDIT_TASK: 'settingedittask',
    SETTING_VIEW_TASK: 'settingviewtask'
};
